import { mapState } from "vuex";
import { getBackUserList } from "@/api/index";
import CompPopupBottom from '../../compose/popupBottom.vue';
import people from "@@/home/people.vue";
export default {
  props: ['show', "pro_id"],
  emits: ['show'],
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  components: {
    CompPopupBottom,
    people
  },
  data() {
    return {
      list: [],
      is_next: true,
      page: 1,
      page_size: 15,
      isLoading: false,
      bottom_text: ''
    };
  },
  methods: {
    async getNext() {
      if (this.is_next && !this.isLoading) {
        this.isLoading = true;
        const res = await getBackUserList({
          pro_id: this.pro_id,
          page: this.page,
          page_size: this.page_size
          // user_id: this.userId
        });
        this.page += 1;
        this.isLoading = false;
        if (res.status == 0) {
          this.list = this.list.concat(res.data.list);
          this.is_next = res.data.is_next;
          this.bottom_text = res.data.bottom_text;
        }
      }
    },
    closePopup() {
      this.$emit('update:show', false);
    }
  }
};