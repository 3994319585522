import CompPopupBottom from '@@/compose/popupBottom.vue';
export default {
  props: ['show'],
  components: {
    CompPopupBottom
  },
  methods: {
    closePopup() {
      this.$emit('update:show', false);
    }
  }
};