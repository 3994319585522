import { mapState, mapActions } from "vuex";
import CompRiskInfo from './riskInfo.vue';
import CompRefundInfo from './popup/refundInfo.vue';
export default {
  props: ["pro_class"],
  components: {
    CompRiskInfo,
    CompRefundInfo
  },
  computed: {
    ...mapState({
      agreement: state => state.agreement.agreement
    }),
    riskInfo: {
      get() {
        let obj = this.agreement.find(item => item.key_name == 'zc_detail_risk_warning');
        if (obj) {
          return obj.content;
        } else {
          return '';
        }
      },
      set() {}
    }
  },
  watch: {
    "riskInfo": {
      handler: function (val) {
        let newText = '';
        this.$nextTick(() => {
          if (val) {
            let num = 0;
            let width = document.querySelector('.agreement-info').getBoundingClientRect().width;
            num = width * 2 / (window.screen.width / 750 * 24) - 4;
            newText = this.riskInfo.substring(0, Math.floor(num)) + '···';
            this.riskTips = newText;
          }
        });
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      riskTips: '',
      show: false,
      showRefundInfo: false,
      proClassArr: [201, 202, 301, 302]
    };
  },
  created() {
    if (!(this.agreement && this.agreement.length)) {
      this.getAgreement();
    }
  },
  methods: {
    ...mapActions({
      getAgreement: "getAgreement"
    }),
    onRefundInfo() {
      this.showRefundInfo = true;
      // location.href = 'https://m.modian.com/about/about_index/3/15'
    },
    onRiskInfo() {
      this.show = true;
    }
  }
};