import { mapState } from "vuex";
import CompReward from "./popup/reword.vue";
import { goToLogin } from "@/utils/user";
export default {
  props: ["product_info", "mina_focus"],
  emits: ["onFavor", "getWxScheme"],
  components: {
    CompReward
  },
  computed: {
    ...mapState({
      ifLogin: state => state.user.ifLogin // 是否登录
    })
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    // 查看回报 
    // 立即购买支持
    onReward() {
      if (this.product_info.is_preview) {
        this.show = true;
      } else {
        if (this.mina_focus) {
          //设置强制跳转小程序时
          this.$emit('getWxScheme');
        } else {
          if (this.ifLogin) {
            this.show = true;
          } else {
            goToLogin();
          }
        }
      }
    },
    // 看好创意
    // 看好并预约项目
    onFavor() {
      this.$emit('onFavor');
    },
    // 去商品详情
    goProduct() {
      location.href = this.product_info.advert;
    },
    // 看好数量
    formatFavorCount(num) {
      if (num) {
        if (num > 10000) {
          return (num / 10000).toFixed(2) + "万";
        } else {
          return num;
        }
      } else {
        return 0;
      }
    }
  }
};