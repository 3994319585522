import domain from '@/service/domain';
import CompMembers from './popup/memberList.vue';
export default {
  props: ["product_info", "user_info"],
  components: {
    CompMembers
  },
  computed: {
    is_preview() {
      return this.$route.query.token ? true : false;
    }
  },
  data() {
    return {
      showMembers: false
    };
  },
  methods: {
    onMembers() {
      if (this.is_preview) {
        return false;
      }
      this.showMembers = true;
    },
    // 去店铺
    goShop() {
      location.href = `${domain.wap}/product/pop/${this.user_info.shop_id}.html`;
    }
  }
};