import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-144cb516"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "goals-box"
};
const _hoisted_2 = {
  class: "goals-tit"
};
const _hoisted_3 = {
  class: "left"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  key: 0,
  class: "icon",
  src: "https://p6.moimg.net/md/icon/pro_goal_true.png"
};
const _hoisted_6 = {
  key: 1,
  class: "icon icon-ing",
  src: "https://p6.moimg.net/md/icon/pro_goal_ing.png"
};
const _hoisted_7 = {
  key: 2,
  class: "icon",
  src: "https://p6.moimg.net/md/icon/pro_goal_false.png"
};
const _hoisted_8 = {
  class: "right"
};
const _hoisted_9 = {
  class: "right-title"
};
const _hoisted_10 = {
  class: "tit"
};
const _hoisted_11 = {
  class: "amount"
};
const _hoisted_12 = {
  class: "promise-tit"
};
const _hoisted_13 = {
  key: 0,
  class: "promise-info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CompPopupBottom = _resolveComponent("CompPopupBottom");
  return _openBlock(), _createBlock(_component_CompPopupBottom, {
    show: $props.show,
    onOpen: $options.open
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString($data.title) + " ", 1), _createElementVNode("i", {
      class: "close",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.closePopup && $options.closePopup(...args))
    })]), _createElementVNode("div", {
      class: _normalizeClass(['goals-list', {
        'goals-list-gray': $props.status_code == 2
      }])
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: index,
        class: "goals-item"
      }, [_createElementVNode("div", _hoisted_3, [index == 0 ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "icon",
        src: item.status == 100 || item.status == 300 ? 'https://p6.moimg.net/md/icon/pro_goal_none.png' : 'https://p6.moimg.net/md/icon/pro_goal_all.png'
      }, null, 8, _hoisted_4)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [item.status == 200 ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : item.status == 100 && item.status_zh ? (_openBlock(), _createElementBlock("img", _hoisted_6)) : (_openBlock(), _createElementBlock("img", _hoisted_7))], 64)), index !== $data.list.length - 1 ? (_openBlock(), _createElementBlock("i", {
        key: 2,
        class: _normalizeClass(['line', {
          'line-success': item.status == 200
        }, {
          'line-transition': item.status == 200 && $data.list[index + 1].status == 100
        }])
      }, null, 2)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("span", _hoisted_10, _toDisplayString(item.title), 1), item.status_zh ? (_openBlock(), _createElementBlock("i", {
        key: 0,
        class: _normalizeClass(['status', {
          'status-success': item.status == 200
        }])
      }, _toDisplayString(item.status_zh), 3)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_11, "￥" + _toDisplayString($options.fNum(item.amount)), 1)]), _createElementVNode("div", _hoisted_12, _toDisplayString(item.promise_title), 1), item.promise ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(item.promise), 1)) : _createCommentVNode("", true)])]);
    }), 128))], 2)])]),
    _: 1
  }, 8, ["show", "onOpen"]);
}