import Error from "@@/common/Error.vue";
import MDRouter from "@/service/router";
import env from "md-base-tools/env";
import cont_match_reg from "@/utils/editor.js";
import { observeVideo } from "md-base-tools/editor/gitvideo";
import redirect from "@/service/redirect";
import { mapState } from "vuex";
import domain from "@/service/domain";
import { getSchemeUrl } from "@/utils/toolsApi";
import CompHeader from "@@/project/header.vue";
import CompTopSwiper from "@@/project/topSwiper.vue";
import CompStatus from "@@/project/status.vue";
import CompInitiator from "@@/project/initiator.vue";
import CompAgreement from "@@/project/agreement.vue";
import CompBottomBtn from "@@/project/bottomBtn.vue";
import CompPopupFavor from "@@/project/popup/popupFavor.vue";
import CompProphet from "@@/project/prophet.vue";
import DrawNew from "@@/update/detail/drawNew.vue";
import { getProjectInfo, getProjectContent, getProjectInfoRt, getQuestionlist, favorProject, getTeamfundTotal, previewProject, ClickShare } from "@/api";
import { mdToast } from "../../utils/tools";
// import Clipboard from 'clipboard';
export default {
  components: {
    Error,
    CompHeader,
    CompTopSwiper,
    CompStatus,
    CompInitiator,
    CompAgreement,
    CompBottomBtn,
    CompPopupFavor,
    CompProphet,
    DrawNew
  },
  computed: {
    ...mapState({
      ifLogin: state => state.user.ifLogin,
      // 是否登录
      userId: state => state.user.userId
      // linkUrl: state => state.user.linkUrl,
    }),
    pro_success() {
      return this.product_info.status_code == 3;
    },
    pro_failed() {
      // 显示失败标识
      return this.product_info.status_code == 2 || this.product_info.status_code == 6 || this.product_info.status_code == 7 || this.product_info.status_code == 8;
    },
    is_preview() {
      return this.$route.query.token ? true : false;
    },
    show_category() {
      let result = false;
      if (this.product_info.category) {
        result = true;
        if (this.rank_info && this.rank_info.rank_tag_name && !this.rank_info.is_total_rank) {
          result = false;
        }
      }
      return result;
    }
  },
  data() {
    return {
      showError: false,
      showMessage: "",
      product_info: {},
      update_info: {},
      user_info: {},
      rank_info: {},
      question_list: [],
      question_total: 0,
      showFavor: false,
      favorType: "success",
      showSendPro: true,
      // 是否显示- 发起众筹
      teamfund_total: 0,
      // 一起筹数量
      loading: true,
      ideaLoading: true,
      weapp: this.$route.query.weapp != "off",
      //是否打开自动调起小程序，默认是
      app_guide: this.$route.query.app_guide != "off",
      //是否打开APP引导下载，默认是
      mina_focus: this.$route.query.mina_focus == "true",
      //是否将用户购买行为强制改为调起小程序，默认否
      share_info: {},
      // 分享详情
      temp_id: this.$route.query.temp_id,
      // 详情预览的请求参数
      token: this.$route.query.token,
      // 详情预览的请求参数
      preview_id: this.$route.query.id,
      // 详情预览的请求参数
      version_id: this.$route.query.version_id || 0,
      // 详情预览的请求参数
      pro_draw_list: [],
      // 抽奖列表

      unique: '' // 点击分享
    };
  },
  async beforeMount() {
    // eslint-disable-next-line no-undef
    userHeader.openapp.style.bottom = "70px";
    if (this.is_preview) {
      this.ideaLoading = false;
      await this.getPreviewInfo();
      this.loading = false;
    } else {
      const pro_id = this.$route.params.id ? parseInt(this.$route.params.id) : "";
      this.pro_id = pro_id;

      // 点击分享
      this.unique = this.$route.query.unique ? this.$route.query.unique : "";
      if (this.unique) {
        this.clickShare();
      }
      await this.getInfo();
      await this.getInfoRt();
      this.loading = false;

      // 打点
      this.sensors.track("ProDetail", {
        page_source: "",
        position_source: "",
        item_name: this.product_info.name || "",
        item_id: this.pro_id || "",
        sec_business_line: this.product_info.pro_class || "",
        category: this.product_info.category || "",
        product_name: "",
        spu_id: "",
        sku_id: "",
        store_id: "",
        brand: ""
      });
      this.setShare();

      // eslint-disable-next-line no-undef
      userHeader.getMdlink({
        type: "project",
        id: pro_id
      });
    }
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.openapp.style.bottom = "0.27rem";
  },
  methods: {
    // 获取预览的众筹详情
    async getPreviewInfo() {
      const {
        status,
        data,
        message
      } = await previewProject({
        pro_id: this.preview_id,
        temp_id: this.temp_id,
        token: this.token,
        version_id: this.version_id
      });
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "项目不存在";
        return;
      } else {
        this.product_info = data.project_info;
        this.product_info.name = data.project_info.title;
        this.product_info.username = data.cp_user_info.user_name;
        this.product_info.user_icon = data.cp_user_info.user_icon;
        // this.product_info.left_time = data.project_info.end_time ? data.project_info.end_time.split(' ')[0] : '';
        this.product_info.is_preview = true;
        this.product_info.reward_list = data.reward_list;
        this.user_info = data.cp_user_info;
        this.$refs.editorBox.appendChild(cont_match_reg(`<p>${data.project_info.content}</p>`));
        this.$nextTick(() => {
          observeVideo(this.$refs.editorBox, process.env.VUE_APP_ENV);
        });
      }
    },
    // 获取众筹详情
    async getInfo() {
      const {
        status,
        data,
        message
      } = await getProjectInfo({
        pro_id: this.pro_id
      });
      // let arr = [0, 1, 2, 3, 4, 6, 7, 8, 9]
      // if (status != 0 || !arr.includes(Number(data.product_info.status_code))) {
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "项目不存在";
        return;
      } else {
        this.product_info = data.product_info;
        this.product_info.is_preview = false;
        this.product_info.reward_list = [];
        this.update_info = data.update_info;
        this.user_info = data.user_info;
        this.share_info = data.product_info.share_info;
        if (data.product_info.pro_class == '102' && data.product_info.idea_id) {
          return this.$router.replace(`/idea/${data.product_info.idea_id}`);
        }
        this.ideaLoading = false;
      }
      console.log('not_support_wx------>', data.not_support_wx, this.weapp, this.product_info.is_forbidden);
      if (this.weapp && this.product_info.is_forbidden != 1) {
        this.getWxScheme();
      }

      // 获取一起筹数据
      if (this.product_info.status_code != 4 && this.product_info.status_code != 1) {
        this.getTeamfund();
      }
      // 常见问题
      if (this.product_info.question_url) {
        this.getQuestionList();
      }
      this.getProjectInfoContent();
    },
    getProjectInfoContent() {
      const _this = this;
      getProjectContent({
        pro_id: this.pro_id
      }).then(res => {
        if (res.status == 0) {
          this.pro_draw_list = res.data.pro_draw_list;
          _this.$nextTick(() => {
            if (!_this.showError && _this.$refs.editorBox) {
              _this.$refs.editorBox.appendChild(cont_match_reg(`<p>${res.data.product_info.content}</p>`));
              _this.$nextTick(() => {
                observeVideo(_this.$refs.editorBox, process.env.VUE_APP_ENV);
              });
            }
          });
        }
      });
    },
    // 获取众筹详情-动态数据
    async getInfoRt() {
      const {
        status,
        data
      } = await getProjectInfoRt({
        pro_id: this.pro_id
      });
      if (status == 0) {
        this.product_info = Object.assign(this.product_info, data.product_info);
        this.update_info = Object.assign(this.update_info, data.update_info);
        this.user_info = Object.assign(this.user_info, data.user_info);
        this.rank_info = Object.assign(this.rank_info, data.product_info.rank_info);
      }
    },
    // 获取常见问题
    async getQuestionList() {
      const {
        status,
        data
      } = await getQuestionlist({
        project_id: this.pro_id,
        page_size: 2
      });
      if (status == 0) {
        this.question_list = data.list;
        this.question_total = data.total;
      }
    },
    goQuestionList() {
      location.href = this.product_info.question_url;
    },
    // 查看更新列表
    goUpdateList() {
      MDRouter.goUpdateList({
        pro_id: this.pro_id
      });
    },
    // 查看更新详情
    goUpdateInfo() {
      MDRouter.goUpdateDetail(this.update_info.id);
    },
    // 看好项目
    async onFavor() {
      if (this.is_preview) {
        return false;
      }
      console.log("this.product_info.if_favor", this.product_info.if_favor);
      if (!this.ifLogin) {
        return redirect.to("login");
      }
      if (this.product_info.if_favor) {
        // 取消看好
        this.favorType = "cancel";
        this.showFavor = true;
      } else {
        // 看好
        favorProject({
          user_id: this.userId,
          pro_id: this.pro_id
        }).then(res => {
          if (res.status == 0) {
            this.product_info.if_favor = true;
            this.product_info.favor_count += 1;
          }
          res.message ? mdToast(res.message) : '';
        });
      }
    },
    // 取消看好
    cancelFavor() {
      favorProject({
        user_id: this.userId,
        pro_id: this.pro_id
      }).then(res => {
        if (res.status == 0) {
          this.product_info.if_favor = false;
          this.product_info.favor_count -= 1;
          this.showFavor = false;
        }
        res.message ? mdToast(res.message) : '';
      });
    },
    // 看好数量
    formatFavorCount(num) {
      if (num) {
        if (num > 10000) {
          return (num / 10000).toFixed(2) + "万";
        } else {
          return num;
        }
      } else {
        return 0;
      }
    },
    // 复制发起众筹链接
    copySendProLink() {
      if (this.is_preview) {
        return false;
      }
      location.href = "https://account.modian.com/m/initiate";
    },
    // 关闭-发起众筹
    closeSendPro() {
      this.showSendPro = false;
    },
    // 获取一起筹数量
    getTeamfund() {
      getTeamfundTotal({
        pro_id: this.pro_id
      }).then(res => {
        if (res.status == 0) {
          this.teamfund_total = res.data.teamfund_total;
        }
      });
    },
    // 查看一起筹榜单/发起一起筹
    goTeamfund() {
      if (this.teamfund_total > 0) {
        // 查看一起筹榜单
        location.href = `${domain.wap}/teamfund/ranking_list/${this.pro_id}`;
      } else {
        // 发起一起筹
        location.href = `${domain.wap}/initiate/teamfund?pro_id=${this.pro_id}`;
      }
    },
    // 去app
    goToApp() {
      if (this.is_preview) {
        return false;
      }
      // eslint-disable-next-line no-undef
      location.href = userHeader.mdlink;
    },
    // 获取小程序scheme url
    async getWxScheme() {
      if (env.isMobile() && !(env.isWeibo() || env.isWxApp() || env.isModianIos() || env.isModianAndroid())) {
        let url = "";
        if (sessionStorage.getItem(this.pro_id)) {
          const obj = JSON.parse(sessionStorage.getItem(this.pro_id));
          let time = Number(obj.initial_time);
          const now = Number(Date.now());
          if (obj.scheme_url && now - time < 23 * 60 * 60 * 1000) {
            // 23小时失效
            url = obj.scheme_url;
          } else {
            url = await this.getScheme();
          }
        } else {
          url = await this.getScheme();
        }
        if (url) {
          location.href = url;
        }
      }
    },
    async getScheme() {
      // const query = this.$route.query || {}
      // for (let key in query) {
      //     str += `&${key}=${query[key]}`
      // }
      let str = window.location.search ? window.location.search.substr(1) : "";
      const param = {
        path: "/pages/project-detail/project-detail",
        query: `pro_id=${this.pro_id}&${str}`
      };
      const option = {
        jump_wxa: JSON.stringify(param)
      };
      var url = await getSchemeUrl(option);
      if (!url) return false;
      sessionStorage.setItem(this.pro_id, JSON.stringify({
        scheme_url: url,
        initial_time: Date.now()
      }));
      return url;
    },
    // 分享
    setShare() {
      let url = `${domain.wap}/project/${this.pro_id}.html?&utm_source=Othershare&utm_content=${this.userId}`;
      let share_option = {
        url,
        img: this.share_info.image_url,
        msg_des: `我在摩点网发现一个有意思的众筹项目：【${this.share_info.title}】看看是否感兴趣？${url}`,
        qq_des: this.share_info.title,
        qq_share_url: `${url}`,
        qq_title: this.share_info.title,
        qr_img: "",
        qzone_share_url: `${url}`,
        share_channel: "",
        share_url: `${url}`,
        sharelink_url: `${url}`,
        weibo_des: `我在摩点网发现一个有意思的众筹项目：【${this.share_info.title}】看看是否感兴趣？${url}（分享自@摩点）`,
        weibo_share_url: `${url}`,
        weibo_title: "",
        weixin_share_url: `${url}`,
        wxTimeline_share_url: `${url}`,
        wx_des: this.share_info.title,
        wx_title: ""
      };
      // eslint-disable-next-line no-undef
      userHeader.setShareInfo(share_option);
    },
    goCategoryList(item) {
      window.location.href = item.h5_link;
    },
    // 点击分享
    clickShare() {
      ClickShare({
        unique_id: this.unique
      });
    }
  }
};