import CompPopupBottom from '../../compose/popupBottom.vue';
import { getProjectGoals } from '@/api/index';
import { fmoney } from '@/utils/tools.js';
export default {
  props: ["show", "status_desc", "pro_id", "status_code"],
  emits: ['show'],
  components: {
    CompPopupBottom
  },
  data() {
    return {
      title: '众筹目标',
      list: []
    };
  },
  methods: {
    async open() {
      const {
        status,
        data
      } = await getProjectGoals({
        pro_id: this.pro_id //99320
      });
      if (status === 0) {
        this.title = data.title;
        this.list = data.list;
      }
    },
    closePopup() {
      this.$emit('update:show', false);
    },
    fNum(num) {
      return fmoney(num, 0);
    }
  }
};