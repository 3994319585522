import MDRouter from "@/service/router";
import CompPopupBottom from '../../compose/popupBottom.vue';
import { getMemberList, getHistoryProjectList } from '@/api/index';
import { mdToast } from "../../../utils/tools";
export default {
  props: ["show", "status_desc", "pro_user_id", "pro_id"],
  components: {
    CompPopupBottom
  },
  data() {
    return {
      title: '项目团队',
      list: [],
      proList: [],
      is_next: true
    };
  },
  methods: {
    toUserhome(item) {
      MDRouter.toUserhome(item.id);
    },
    open() {
      this.getProList();
      this.getMembers();
    },
    closePopup() {
      this.$emit('update:show', false);
    },
    async getMembers() {
      const {
        status,
        data
      } = await getMemberList({
        pro_id: this.pro_id
      });
      if (status == 0) {
        // this.title = data.title
        this.list = data;
      }
    },
    async getProList() {
      const {
        status,
        message,
        data
      } = await getHistoryProjectList({
        pro_id: this.pro_id,
        to_user_id: this.pro_user_id,
        page: 1,
        page_size: 20
      });
      if (status == 0) {
        this.proList = data.list;
        this.is_next = data.is_next;
      } else {
        message && mdToast(message);
      }
    },
    // 跳转至项目详情
    goProInfo(id) {
      MDRouter.goProInfo(id);
    }
  }
};