import { showVideoList } from "@/api";
import { getListOne } from "@/utils/tools";
import CompVideo from './video.vue';
import { domClientWidth, domPixelRatio } from "md-base-tools/env";
export default {
  props: {
    product_info: {
      // 项目详情
      type: Object
    }
  },
  components: {
    CompVideo
  },
  computed: {
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  data() {
    return {
      videoUrl: '',
      if_video_play: false,
      currentIndex: 1
    };
  },
  methods: {
    // 播放视频
    async playVideo() {
      if (this.product_info.vedio_source && this.currentIndex == 1) {
        const res = await showVideoList({
          url: this.product_info.vedio_source
        });
        if (res.status == 0) {
          let defaultValue = res.data.default ? res.data.default : getListOne(res.data.list);
          let new_src = res.data.list[defaultValue];
          this.videoUrl = new_src;
          this.if_video_play = true;
        }
      }
    },
    swiperChange(index) {
      this.currentIndex = index + 1;
    },
    showImg() {
      this.if_video_play = false;
    }
  }
};