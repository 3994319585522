import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d5c1bd0c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "risk-info-box"
};
const _hoisted_2 = {
  class: "risk-info-tit"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CompPopupBottom = _resolveComponent("CompPopupBottom");
  return _openBlock(), _createBlock(_component_CompPopupBottom, {
    show: $props.show
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString($data.title) + " ", 1), _createElementVNode("i", {
      class: "close",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.closePopup && $options.closePopup(...args))
    })]), _createElementVNode("div", {
      class: "info",
      innerHTML: $options.riskInfo
    }, null, 8, _hoisted_3)])]),
    _: 1
  }, 8, ["show"]);
}