import { mapState } from "vuex";
import CompPopupBottom from '../compose/popupBottom.vue';
export default {
  props: ["show"],
  components: {
    CompPopupBottom
  },
  computed: {
    ...mapState({
      agreement: state => state.agreement.agreement
    }),
    riskInfo() {
      let obj = this.agreement.find(item => item.key_name == 'zc_detail_risk_warning');
      if (obj) {
        let str = String(obj.content);
        return '<p style="margin-bottom: 12px;">' + str.replace(/\n/g, '</p><p style="margin-bottom: 12px;">') + '</p>';
      } else {
        return {};
      }
    }
  },
  data() {
    return {
      title: '风险提示',
      list: []
    };
  },
  methods: {
    closePopup() {
      this.$emit('update:show', false);
    }
  }
};