export default {
  props: ['show', 'type'],
  emits: ['show', 'cancelFavor'],
  computed: {
    showPopup: {
      get() {
        return this.show;
      },
      set() {}
    }
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    cancelFavor() {
      this.$emit('cancelFavor');
    }
  }
};